var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isBugReporter
      ? _c(
          "div",
          {
            staticClass:
              "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
          },
          [
            !_vm.screenRecord.isRecording
              ? _c("div", { staticClass: "kt-portlet kt-callout" }, [
                  _c("div", { staticClass: "kt-portlet__body" }, [
                    _c("div", { staticClass: "kt-callout__body" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "kt-callout__action mt-5" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-custom btn-bold btn-upper btn-font-sm btn-primary",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.startScreenRecord()
                              },
                            },
                          },
                          [_vm._v(" Start Recording ")]
                        ),
                      ]),
                    ]),
                    _c("form", { staticClass: "kt-form pt-5 mt-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "pb-3" }, [
                          _vm._v("Recording Options"),
                        ]),
                        _c("div", { staticClass: "kt-radio-inline" }, [
                          _c("label", { staticClass: "kt-radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.recordVideo,
                                  expression: "recordVideo",
                                },
                              ],
                              attrs: { type: "radio", name: "recordVideo" },
                              domProps: {
                                value: true,
                                checked: _vm._q(_vm.recordVideo, true),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.recordVideo = true
                                },
                              },
                            }),
                            _vm._v("Record Video And Audio"),
                            _c("span"),
                          ]),
                          _c("label", { staticClass: "kt-radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.recordVideo,
                                  expression: "recordVideo",
                                },
                              ],
                              attrs: { type: "radio", name: "recordVideo" },
                              domProps: {
                                value: false,
                                checked: _vm._q(_vm.recordVideo, false),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.recordVideo = false
                                },
                              },
                            }),
                            _vm._v("Textual Bug Report"),
                            _c("span"),
                          ]),
                        ]),
                        _c("span", { staticClass: "form-text text-muted" }),
                      ]),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "kt-portlet" }, [
                  _c("div", { staticClass: "kt-portlet__head" }, [
                    _c("div", { staticClass: "kt-portlet__head-label" }, [
                      _vm.hasRecording
                        ? _c("h3", { staticClass: "kt-portlet__head-title" }, [
                            _vm._v(" Report a Bug "),
                          ])
                        : _c(
                            "h3",
                            {
                              staticClass:
                                "kt-portlet__head-title kt-font-danger",
                            },
                            [_vm._v(" Recording in progress ")]
                          ),
                    ]),
                  ]),
                  _vm.isRecording
                    ? _c("div", { staticClass: "kt-portlet__body" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "p",
                            { staticClass: "my-3 mx-4" },
                            [
                              _vm.screenRecord.isRecording
                                ? [
                                    _c("strong", [
                                      _vm._v("You are now recording"),
                                    ]),
                                    _vm._v(
                                      ", Proceed to demonstrate the problem you are experiencing by navigating to the problematic pages. This process captures additional debugging information to be sent with your issue. When you're done, click the "
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "kt-font-danger" },
                                      [
                                        _vm._v("STOP "),
                                        _c("SVGIcon", {
                                          attrs: {
                                            name: "record",
                                            "hex-color": "#fd397a",
                                          },
                                        }),
                                        _vm._v(" ICON"),
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      " in the upper right to the submit the bug report. "
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _c(
                                      "span",
                                      { staticClass: "kt-font-danger" },
                                      [
                                        _vm._v(
                                          "Do not reload this tab or the recording will be terminated."
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "kt-portlet__foot" }, [
                    _c("div", { staticClass: "kt-form__actions" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.stopScreenRecord.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-stop" }),
                          _vm._v(" Create issue "),
                        ]
                      ),
                    ]),
                  ]),
                ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-callout__content" }, [
      _c("h3", { staticClass: "kt-callout__title" }, [
        _vm._v(" Submit an issue "),
      ]),
      _c("p", { staticClass: "kt-callout__desc" }, [
        _vm._v(
          " Healthy criticism, discussion and suggestions for improvements are welcome. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }