<template>
<div>
    <div
        v-if="isBugReporter"
        class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
    >
        <div
            v-if="!screenRecord.isRecording"
            class="kt-portlet kt-callout"
        >
            <div class="kt-portlet__body">
                <div class="kt-callout__body">
                    <div class="kt-callout__content">
                        <h3 class="kt-callout__title">
                            Submit an issue
                        </h3>
                        <p class="kt-callout__desc">
                            Healthy criticism, discussion and suggestions for improvements are welcome.
                        </p>
                    </div>
                    <div class="kt-callout__action mt-5">
                        <a
                            href="#"
                            class="btn btn-custom btn-bold btn-upper btn-font-sm btn-primary"
                            @click.stop.prevent="startScreenRecord()"
                        >
                            Start Recording
                        </a>
                    </div>
                </div>

                <form class="kt-form pt-5 mt-2">
                    <div class="form-group">
                        <label class="pb-3">Recording Options</label>
                        <div class="kt-radio-inline">
                            <label class="kt-radio">
                                <input
                                    v-model="recordVideo"
                                    type="radio"
                                    :value="true"
                                    name="recordVideo"
                                >Record Video And Audio<span />
                            </label>
                            <label class="kt-radio">
                                <input
                                    v-model="recordVideo"
                                    type="radio"
                                    :value="false"
                                    name="recordVideo"
                                >Textual Bug Report<span />
                            </label>
                        </div>
                        <span class="form-text text-muted" />
                    </div>
                </form>
            </div>
        </div>

        <div
            v-else
            class="kt-portlet"
        >
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3
                        v-if="hasRecording"
                        class="kt-portlet__head-title"
                    >
                        Report a Bug
                    </h3>
                    <h3
                        v-else
                        class="kt-portlet__head-title kt-font-danger"
                    >
                        Recording in progress
                    </h3>
                </div>
            </div>
            <div
                v-if="isRecording"
                class="kt-portlet__body"
            >
                <div class="row">
                    <p class="my-3 mx-4">
                        <template v-if="screenRecord.isRecording">
                            <strong>You are now recording</strong>,

                            Proceed to demonstrate the problem you are experiencing by navigating to the problematic pages.

                            This process captures additional debugging information to be sent with your issue.

                            When you're done, click the <span class="kt-font-danger">STOP

                                <SVGIcon
                                    name="record"
                                    hex-color="#fd397a"
                                />

                                ICON</span> in the upper right to the submit the bug report.

                            <br><br>

                            <span class="kt-font-danger">Do not reload this tab or the recording will be terminated.</span>
                        </template>
                    </p>
                </div>
            </div>
            <div class="kt-portlet__foot">
                <div class="kt-form__actions">
                    <button
                        type="button"
                        class="btn btn-danger"
                        @click.stop.prevent="stopScreenRecord"
                    >
                        <i
                            class="fa fa-stop"
                        />
                        Create issue
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import async from 'async';
import * as network from '../network';
import fileUtilities from '../lib/fileUtilities';
import Types from '../store/Types';
import userMixins from '../store/mixins/userMixins';

export default {
    name: 'CreateBugReport',
    components: {
    },
    mixins: [userMixins],
    data() {
        return {
            recordVideo: true,
            bugDescription: null,
            bugTitle: null,
            expiringLink: null,
            saving: false,

        };
    },
    computed: {
        user() { return this.$store.state.user; },
        screenRecord() { return this.$store.state.screenRecord; },
        requestType: {
            get() {
                return this.selectedRequestType;
            },
            set(requestType) {
                this.selectedRequestType = requestType;
            },
        },
        file() {
            return this.screenRecord.recordingFile;
        },
        isRecording() {
            return this.screenRecord.isRecording;
        },
        hasRecording() {
            return this.screenRecord.history.length > 0;
        },
        isBugReporter() {
            const v = this;
            if (v.user.isSuperAdmin || ['School Admin'].includes(v.user.school.role)) return true;
            return v.$_userMixins_isSchoolUser && v.user.userPermissions.canSubmitBugReports;
        },
        recordingDownload() {
            const v = this;
            if (!v.file) return null;

            const blob = v.file.slice(0, v.file.size, 'video/webm;codecs=avc1,opus');
            const newObjectUrl = URL.createObjectURL(blob);
            return newObjectUrl;
        },
    },
    methods: {
        startScreenRecord() {
            const { showError, recordVideo, $store } = this;
            $store.dispatch(Types.actions.START_SCREEN_RECORD, {
                recordVideo,
                callback(err) {
                    if (err) return showError('Unable to start recording, please accept all permissions requested by the browser when asked.');
                },
            });
        },
        stopScreenRecord() {
            const { $store, $router } = this;
            $store.dispatch(Types.actions.STOP_SCREEN_RECORD, () => {
                $router.push({ name: 'SubmitBugReport' });
            });
        },
        deleteRecording() {
            const v = this;
            v.$store.commit(Types.mutations.RESET_SCREEN_RECORD);
        },
        // submitBugReport() {
        //     const v = this;
        //     const { showError } = this;
        //     if (this.saving) return;

        //     if (!v.bugTitle) return showError('Please enter a Title');
        //     if (!v.bugDescription) return showError('Please enter a description');
        //     if (!v.file) return showError('Please record a video first');
        //     this.saving = true;

        //     async.auto({
        //         uploadParameters(next) {
        //             const { schoolId, schoolTermId } = v.user.school;
        //             const params = {
        //                 url: {
        //                     schoolId,
        //                     schoolTermId,
        //                     fileType: 'bug-reports',
        //                 },
        //                 body: {
        //                     fileParameters: {
        //                         fileName: v.file.name,
        //                     },
        //                 },
        //             };
        //             network.storage.getAWSFormDetails(params, next);
        //         },
        //         aws: ['uploadParameters', function (results, next) {
        //             fileUtilities.awsUpload(results.uploadParameters, v.file, v.file, next);
        //         }],
        //         complete: ['aws', function (results, next) {
        //             if (v.file && v?.file?.error) return next(v.file.error);

        //             const { bucketPath } = v.file;
        //             const { bugTitle, bugDescription } = v;
        //             const { schoolId, schoolTermId } = v.user.school;
        //             // const bugHistory = screenRecord.history;
        //             const bugHistory = window.syncGrades.history;
        //             const { logs } = window.syncGrades;
        //             const params = {
        //                 url: { schoolId, schoolTermId },
        //                 body: {
        //                     bugReport: {
        //                         bucketPath,
        //                         bugTitle,
        //                         bugDescription,
        //                         bugHistory,
        //                         logs,
        //                     },
        //                 },
        //             };

        //             network.schools.createBugReport(params, (err, res) => {
        //                 if (err) return next(err);
        //                 next(err, res);
        //             });
        //         }],
        //     }, 10, (err) => {
        //         this.saving = false;
        //         if (err) return v.showError(err);

        //         this.$bvToast.toast('Bug has been submitted', {
        //             variant: 'success',
        //             solid: true,
        //         });
        //         v.$store.commit(Types.mutations.RESET_SCREEN_RECORD);
        //     });
        // },
    },
};
</script>

<style>
.button-width {
    width: 3.75rem;
}
</style>
